// https://chakra-ui.com/docs/styled-system/features/semantic-tokens

// chakra will generate a css variable for every semantic token. For example:
// css variable generated for `colors: { border.gray }` is `--chakra-colors-border-gray`.

export const semanticTokens = {
  colors: {
    "border.gray": {
      default: "gray.200",
      _dark: "gray.700",
    },
    "bg.pure": {
      default: "white",
      _dark: "#0A1218",
    },
    "bg.pure.reverse": {
      default: "#0A1218",
      _dark: "white",
    },
    "bg.pale": {
      default: "gray.100",
      _dark: "gray.800",
    },
    "text.normal": {
      default: "gray.800",
      _dark: "gray.100",
    },
    "text.normal.reverse": {
      default: "gray.100",
      _dark: "gray.800",
    },
    "text.pale": {
      default: "gray.600",
      _dark: "gray.400",
    },
    "text.pale.extra": {
      default: "gray.500",
      _dark: "gray.500",
    },
    "text.brand": {
      default: "brand.500",
      _dark: "brand.300",
    },
    "text.error": {
      default: "red.500",
      _dark: "red.300",
    },
    "text.success": {
      default: "green.600",
      _dark: "green.300",
    },
    "input.bg": {
      default: "white",
      _dark: "gray.900",
    },
  },
};
